import "./App.scss";
import { Route, Routes, BrowserRouter, useParams } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import { useTranslation } from "react-i18next";
import { useContext, useEffect } from "react";

import { AuthStateContext } from "./Context/MainContext";
import CampaignDetails from "./Views/Campagins/CampaignDetails/CampaignDetails";
import './assets/fonts/fontello/css/fontello.css'
import React from "react";
import AuthLayout from "./layout/AuthLayout";
import { getAgencyData } from "./Context/Actions/agency";
import getPalette from "tailwindcss-palette-generator";
function App() {
  const { state, dispatch } = useContext(AuthStateContext);
  const {
    t,
    i18n: { changeLanguage, dir },
  } = useTranslation();
  useEffect(() => {
    document.body.dir = dir();
  }, []);

  const loadTheme = (primaryColor: string) => {
    if (primaryColor) {
      const palette = getPalette({ color: primaryColor, name: 'primary' })
      document.documentElement.style.setProperty('--primary-700', palette.primary["700"])
      document.documentElement.style.setProperty('--primary-600', palette.primary["600"])
      document.documentElement.style.setProperty('--primary-500', palette.primary["500"])
      document.documentElement.style.setProperty('--primary-400', palette.primary["400"])
      document.documentElement.style.setProperty('--primary-300', palette.primary["300"])
      document.documentElement.style.setProperty('--primary-200', palette.primary["200"])
      document.documentElement.style.setProperty('--primary-100', palette.primary["100"])
      document.documentElement.style.setProperty('--primary-50', palette.primary["50"])
    }
  }


  useEffect(() => {
    const getData = async () => {
      const agency = await getAgencyData(window.location.pathname.split("/")[1]);
      dispatch({ type: "AGENCY_DATA", payload: agency.basicInfo })
      localStorage.setItem("agency", JSON.stringify(agency.basicInfo))
      loadTheme(agency.basicInfo?.primaryColor)
      console.log(agency, "agency")
    }
    getData()
  }, [])

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route
            path="/:id"
            element={state?.agency &&
              <PrivateRoute>
                <CampaignDetails />
              </PrivateRoute>
            }
          />

        </Routes>
      </BrowserRouter>
    </div>
  );


}

export default App;
