import { useTranslation } from "react-i18next";
import styles from "./CampaignDetails.module.scss";
import {
  HiOutlineArrowPathRoundedSquare,
  HiOutlineClock,
  HiOutlineRocketLaunch,
  HiOutlineShare,
  HiOutlineStopCircle,
  HiOutlineTrophy,
  HiOutlineCheckCircle,
  HiOutlinePencilSquare,
  HiOutlineLink,
  HiOutlineArrowUpOnSquare,
  HiShare,
} from "react-icons/hi2";
import { useEffect, useState, useContext, useRef } from "react";
import {
  CampaignDetailsInterface,
  exportCampaignAssignedInfluencers,
  getCampaign,
  getCampaignAssignedInfluencers,
  getCampaignSummary,
} from "../../../Context/Actions/campaign";
import { useParams } from "react-router-dom";
import i18n from "../../../Utils/i18n/i18n";
import { AuthStateContext } from "../../../Context/MainContext";
import {
  Row,
  Col,
  Space,
  notification,
  Button,
  Tabs,
  Dropdown,
  Collapse,
  Radio,
  Select,
  Tooltip,
} from "antd";
import {
  HiOutlineDownload,
  HiOutlineExternalLink,
  HiOutlineUserGroup,
  HiOutlineXCircle,
} from "react-icons/hi";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import relativeTime from "dayjs/plugin/relativeTime";
import arabicLocale from "dayjs/locale/ar";
import updateLocale from "dayjs/plugin/updateLocale";
import BrandPlaceholderImg from "../../../assets/imgs/brand_placeholder.svg";
import assingedInfluencerEmptyState from "../../../assets/imgs/assingedInfluencerEmptyState.svg";
import PageNotFound from "../../../Components/PageNotFound";
import InfluencerServicesCard from "../../../Components/InfluencerServicesCard";
import {
  exportExcelInfs,
  influencerMappedData,
  influencerMappedDataPPT,
  isValidURL,
  mapExportExcelInfsData,
} from "../../../Utils/helpers";
import InfluencerServicesCardShimmer from "../../../Components/InfluencerServicesCardShimmer";
import { t } from "i18next";
import LogRocket from "logrocket";
import EnhancedInfluencerServicesCard from "../../../Components/EnhancedInfluencerServicesCard";
import PPTExport from "../../../Components/pptExport/Index";
import ExportModal from "../../../Components/ExportModal";
import { TbCloudDownload } from "react-icons/tb";
import { RiFileChartLine, RiListCheck2 } from "react-icons/ri";
import CampaignSummary from "./CampaignSummary";
import { getAgencyData } from "../../../Context/Actions/agency";
import getPalette from "tailwindcss-palette-generator";

dayjs.extend(utc);
dayjs.extend(relativeTime);
dayjs.extend(updateLocale);

// Set the locale to Arabic
function addMetaTags(data: any) {
  // Create meta tags
  const metaTags = [
    { name: "description", content: data.description },
    { property: "og:title", content: data.title },
    // Add more meta tags as needed
  ];

  // Get the head element of the document
  const head = document.head || document.getElementsByTagName("head")[0];

  // Iterate through the metaTags array and create meta elements
  metaTags.forEach((meta) => {
    const metaTag = document.createElement("meta");
    // Set attributes for the meta tag
    for (const [key, value] of Object.entries(meta)) {
      metaTag.setAttribute(key, value);
    }
    // Append the meta tag to the head element
    head.appendChild(metaTag);
  });
}
const CampaignDetails = () => {
  const {
    t,
    i18n: { dir },
  } = useTranslation();
  const { id } = useParams();

  const {
    state: { lookups },
    dispatch,
    state,
  } = useContext(AuthStateContext);
  const [details, setDetails] = useState<CampaignDetailsInterface | null>(null);
  const [assignedInfluencers, setAssignedInfluencers] = useState<any | null>(
    null
  );

  const [loading, setLoading] = useState(false);
  const [assingedInfluencersLoading, setAssingedInfluencersLoading] =
    useState(false);
  const [statusLoading, setStatusLoading] = useState(false);
  const [status, setStatus] = useState("");
  const [hasError, setHasError] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const [PPTInfluencers, setPPTInfluencers] = useState<any | null>(null);

  const [showPPTExportLink, setShowPPTExportLink] = useState(false);

  const [showExportModal, setShowExportModal] = useState(false);

  const [filters, setFilters] = useState({ status: "", platform: "" });

  const [summary, setSummary] = useState<any>(null);
  const [summaryLoading, setSummaryLoading] = useState(false);

  const loadTheme = (primaryColor: string) => {
    if (primaryColor) {
      const palette = getPalette({ color: primaryColor, name: 'primary' })
      document.documentElement.style.setProperty('--primary-700', palette.primary["700"])
      document.documentElement.style.setProperty('--primary-600', palette.primary["600"])
      document.documentElement.style.setProperty('--primary-500', palette.primary["500"])
      document.documentElement.style.setProperty('--primary-400', palette.primary["400"])
      document.documentElement.style.setProperty('--primary-300', palette.primary["300"])
      document.documentElement.style.setProperty('--primary-200', palette.primary["200"])
      document.documentElement.style.setProperty('--primary-100', palette.primary["100"])
      document.documentElement.style.setProperty('--primary-50', palette.primary["50"])
    }
  }


  // useEffect(() => {
  //   const agency = JSON.parse(localStorage.getItem("agency")!)
  //   if (agency.primaryColor) {
  //     loadTheme(agency.primaryColor)
  //   }
  // }, [])

  const getCampaignSummaryFn = async () => {
    setSummary(null);
    setSummaryLoading(true);
    await getCampaignSummary(id, filters).then((res: any) => {
      setSummary(res);
      if (res.error) {
        notification.open({
          placement: dir() === "rtl" ? "topLeft" : "topRight",
          message: t("error_label"),
          description: t("something_went_wrong"),
          icon: <HiOutlineXCircle className="danger-text" />,
          className: "no-wrap",
        });
      }
      setSummaryLoading(false);
    });
  };

  const openNotification = () => {
    notification.open({
      placement: dir() === "rtl" ? "topLeft" : "topRight",
      message: t("error_label"),
      description: t("something_went_wrong"),
      icon: <HiOutlineXCircle className="danger-text" />,
      className: "no-wrap",
    });
  };
  const updateLocalization = (language: string) => {
    // Customize the locale strings
    if (language == "ar") {
      dayjs.locale("ar", arabicLocale);
    } else {
      dayjs.locale("en");
    }
    dayjs.updateLocale("ar", {
      relativeTime: {
        future: "بعد %s",
        past: "منذ %s",
        s: "ثوانٍ",
        m: t("minute"),
        mm: (number: number) => {
          if (number === 2) {
            return `${t("two_mins")}`;
          } else if (number > 10) {
            return `${number} ${t("minute")}`;
          } else {
            return `${number} ${t("minutes")}`;
          }
        },
        h: t("hour"),
        hh: (number: number) => {
          if (number === 2) {
            return `${t("two_hours")}`;
          } else if (number > 10) {
            return `${number} ${t("hour")}`;
          } else {
            return `${number} ${t("hours")}`;
          }
        },
        d: t("day"),
        dd: (number: number) => {
          if (number === 2) {
            return `${t("two_days")}`;
          } else if (number > 10) {
            return `${number} ${t("day")}`;
          } else {
            return `${number} ${t("days")}`;
          }
        },
        M: t("month"),
        MM: (number: number) => {
          if (number === 2) {
            return `${t("two_months")}`;
          } else if (number > 10) {
            return `${number} ${t("month")}`;
          } else {
            return `${number} ${t("months")}`;
          }
        },
        y: t("year"),
        yy: (number: number) => {
          if (number === 2) {
            return `${t("two_years")}`;
          } else if (number > 10) {
            return `${number} ${t("year")}`;
          } else {
            return `${number}  ${t("years")}`;
          }
        },
      },
    });
  };
  const getCampaignDetails = async (wihtoutLoading: boolean = false) => {
    !wihtoutLoading && setLoading(true);
    let c: CampaignDetailsInterface = await getCampaign(id as string);
    if (c.error) {
      if (c.error === "generic_error") {
        openNotification();
        // setLoading(false);
      } else {
        setHasError(true);
        // openNotification();
        setLoading(false);
      }
    } else {
      addMetaTags({
        description: `Campaign for ${c.campaignSummary.brand.name}`,
        title: c.campaignSummary.name,
      });
      !wihtoutLoading && getCampaignAssignedInfluencersList();

      c.campaignSummary.campaignObjectiveKey =
        lookups &&
        lookups[i18n.language].campaignObjectives.find(
          (o: any) => o.key == c.campaignSummary.campaignObjectiveKey
        ).name;
      c.influencerAndServices.campaignNicheKeys =
        c.influencerAndServices.campaignNicheKeys
          .map(
            (k: any, i: any) =>
              lookups &&
              lookups[i18n.language]?.niches.find((t: any) => t.key == k)?.name
          )
          .join(", ");
      c.influencerAndServices.influencerTypeKeys =
        c.influencerAndServices.influencerTypeKeys.map((k: any, i: any) => (
          <Tooltip
            title={t(
              lookups[i18n.language]?.influencerTypes.find(
                (t: any) => t.key == k
              ).key + "_info"
            )}
          >
            {lookups &&
              lookups[i18n.language]?.influencerTypes.find(
                (t: any) => t.key == k
              ).name}
            {i + 1 < c.influencerAndServices.influencerTypeKeys.length
              ? ", "
              : ""}
          </Tooltip>
        ));
      c.influencerAndServices.socialPlatforms =
        c.influencerAndServices.socialPlatforms.map((p: any) => {
          const sp =
            lookups &&
            lookups[i18n.language].socialPlatforms.find(
              (s: any) => p.key == s.key
            );
          const services = p.servicesKeys.map(
            (k: string) =>
              sp.socialPlatformServices.find((sps: any) => sps.key == k).name
          );
          return {
            key: sp.key,
            logo: sp.logoUrl,
            name: sp.name,
            services: services,
            servicesKeys: p.servicesKeys,
          };
        });
      c.name = c.campaignSummary.name;
      setDetails(c);
      setStatus(c.campaignSummary.campaignStatusKey);
      setLoading(false);
      LogRocket.init(process.env.REACT_APP_LOGROCKET_ID!);
      LogRocket.identify(
        `User : Anonymous - Agency : ${c.agencyInfo.basicInfo.name} - Brand : ${c.campaignSummary.brand.name}`
      );
    }
    updateLocalization(i18n.language);
  };

  const getCampaignAssignedInfluencersList = async (
    withoutLoad: any = false
  ) => {
    getCampaignSummaryFn();
    document.querySelector("body")?.classList.add("noscroll");
    setModalOpen(false);
    !withoutLoad && setAssingedInfluencersLoading(true);
    dispatch({ type: "LOAD_INFs", payload: true });
    try {
      const res = await getCampaignAssignedInfluencers(id, filters);
      const assignedInfluencers: any[] = [];

      if (res.data?.length > 0) {
        await Promise.all(
          res.data?.map(async (influencer: any) => {
            if (state && lookups && lookups[i18n.language]) {
              const mappedInfluencer = await influencerMappedData(
                state,
                influencer
              );
              assignedInfluencers.push(mappedInfluencer);
            }
          })
        );
        updateLocalization(i18n.language);
      }
      if (res.error) {
        notification.open({
          placement: dir() === "rtl" ? "topLeft" : "topRight",
          message: t("error_label"),
          description: t("something_went_wrong"),
          icon: <HiOutlineXCircle className="danger-text" />,
          className: "no-wrap",
        });
      }
      await setAssignedInfluencers(assignedInfluencers);

      dispatch({ type: "CHANGE_STATUS", payload: false });
      setAssingedInfluencersLoading(false);
      dispatch({ type: "LOAD_INFs", payload: false });
      document.querySelector("body")?.classList.remove("noscroll");
      if (collapsedView) {
        const cont = document.querySelector(".collapedAssignedList");
        if (cont) {
          console.log("scrollIntoView");
          setTimeout(() => {
            cont.scrollIntoView({ behavior: "smooth" });
          }, 300);
        }
      }
    } catch (error) {
      console.log("catch error assigned");
      // Handle any errors here
      notification.open({
        placement: dir() === "rtl" ? "topLeft" : "topRight",
        message: t("error_label"),
        description: t("something_went_wrong"),
        icon: <HiOutlineXCircle className="danger-text" />,
        className: "no-wrap",
      });
    }
    setAssingedInfluencersLoading(false);
    dispatch({ type: "LOAD_INFs", payload: false });
    document.querySelector("body")?.classList.remove("noscroll");
  };
  const imgRef = useRef(null);
  useEffect(() => {
    const handleOnChangeLanguage = () => {
      setLoading(true);
      getCampaignDetails(true);
      getCampaignAssignedInfluencersList();
      updateLocalization(i18n.language);
    };
    i18n.on("languageChanged", handleOnChangeLanguage);

    return () => {
      // Clean up the event listener when the component unmounts
      i18n.off("languageChanged", handleOnChangeLanguage);
    };
  }, [i18n.language, state.loading]);
  useEffect(() => {
    if (!lookups) {
      setLoading(true);
    }
    if (lookups) {
      setLoading(true);
      getCampaignDetails(true);
      getCampaignAssignedInfluencersList();
    }
  }, [lookups]);

  useEffect(() => {
    getCampaignAssignedInfluencersList();
  }, [filters]);

  const handleImgError = (e: any) => {
    e.target.src = BrandPlaceholderImg;
  };
  const returnStatusIcon = (status: any) => {
    if (status.toLowerCase() == "new") {
      return <HiOutlineRocketLaunch width={18} height={18} />;
    } else if (status.toLowerCase() == "planning") {
      return <HiOutlinePencilSquare width={18} height={18} />;
    } else if (status.toLowerCase() == "accepted") {
      return <HiOutlineCheckCircle width={18} height={18} />;
    } else if (status.toLowerCase() == "waiting") {
      return <HiOutlineClock width={18} height={18} />;
    } else if (status.toLowerCase() == "running") {
      return <HiOutlineArrowPathRoundedSquare width={18} height={18} />;
    } else if (status.toLowerCase() == "cancelled") {
      return <HiOutlineXCircle width={18} height={18} />;
    } else if (status.toLowerCase() == "paused") {
      return <HiOutlineStopCircle width={18} height={18} />;
    } else if (status.toLowerCase() == "completed") {
      return <HiOutlineTrophy width={18} height={18} />;
    }
  };
  let labelClass = "";

  if (status === "Cancelled") {
    labelClass = "label-danger";
  } else if (status === "Accepted" || status === "Completed") {
    labelClass = "label-success";
  } else if (status === "Planning") {
    labelClass = "label-warning";
  } else if (status === "Waiting") {
    labelClass = "label-warning-700";
  } else if (status === "New") {
    labelClass = "label-info";
  } else if (status === "Running") {
    labelClass = "label-info-700";
  } else if (status === "Paused") {
    labelClass = "label-neutral";
  } else {
    labelClass = "";
  }

  const campaignStatus =
    lookups &&
    lookups[i18n.language]?.campaignStatuses?.find(
      (s: any) => s.key === status
    );

  const { Option } = Select;

  useEffect(() => {
    if (state.loading) {
      setAssingedInfluencersLoading(true);
    } else {
      if (!assingedInfluencersLoading) {
        setAssingedInfluencersLoading(false);
      }
    }
  }, [state.loading]);

  const [collapsedView, setCollapsedView] = useState(false);

  const handleScroll = (e: any) => {
    if (assignedInfluencers.length) {
      if (e.target.scrollTop > 0) {
        setCollapsedView(true);
      } else {
        setCollapsedView(false);
      }
    }
  };

  return (
    <>
      {hasError ? (
        <PageNotFound />
      ) : (
        <>
          <div className={styles.desktopView}>
            <Row className={styles.row}>
              <Col span={6} className={styles.col}>
                <div className={styles.Header}>
                  {loading ? (
                    <div className={styles.paddingCont}>
                      <div className={styles.logo}>
                        <div className={`${styles.loadingImg} shimmer`}></div>
                        <div className={styles.header_info}>
                          <h3 className={`${styles.loadingname} shimmer`}></h3>

                          <h3 className={`${styles.loadingname} shimmer`}></h3>

                          <h3 className={`${styles.loadingname} shimmer`}></h3>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className={styles.paddingCont}>
                        <div className={styles.head}>
                          <img
                            ref={imgRef}
                            src={details?.campaignSummary?.brand?.logo}
                            alt="brand"
                            onError={handleImgError}
                          />
                        </div>
                        <h3 data-private>
                          {i18n.language == "en"
                            ? details?.campaignSummary?.brand?.name.en
                            : details?.campaignSummary?.brand?.name.ar}{" "}
                          {details?.campaignSummary?.brand?.websiteUrl &&
                            isValidURL(
                              details?.campaignSummary?.brand?.websiteUrl
                            ) ? (
                            <a
                              target="_blank"
                              href={details?.campaignSummary?.brand?.websiteUrl}
                            >
                              <HiOutlineExternalLink />
                            </a>
                          ) : (
                            ""
                          )}
                        </h3>
                        <h2 data-private>{details?.campaignSummary.name}</h2>
                        <Button
                          className={`${styles.status_label} ${labelClass}`}
                        >
                          {statusLoading ? (
                            <span className="icon-loader spin"></span>
                          ) : (
                            <Space>
                              {returnStatusIcon(status)}
                              {campaignStatus?.name}
                            </Space>
                          )}
                        </Button>
                        <div className={`${styles.lastActivity} body`}>
                          {t("latest_activity_on")}{" "}
                          {dayjs
                            .utc(
                              details?.auditSummary?.modificationInfo
                                ?.modificationDate
                            )
                            .local()
                            .format("DD/MM/YYYY  hh:mm A")}
                        </div>
                      </div>
                      <Collapse
                        items={[
                          {
                            key: "1",
                            label: (
                              <p className="alignItems">
                                <RiListCheck2 /> {t("campaign_details")}
                              </p>
                            ),
                            children: (
                              <div className={styles.collapseDetails}>
                                <div className={styles.collapseDetailsHead}>
                                  <div>
                                    <p>{t("create_campaign_objective")}</p>
                                    <span>
                                      {
                                        details?.campaignSummary
                                          .campaignObjectiveKey
                                      }
                                    </span>
                                  </div>

                                  <div>
                                    <p>{t("influencer_type")}</p>
                                    <span className={styles.gap}>
                                      {details?.influencerAndServices?.influencerTypeKeys.map(
                                        (e: any) => e
                                      )}
                                    </span>
                                  </div>
                                </div>
                                <ul className={styles.plats}>
                                  {details?.influencerAndServices?.socialPlatforms.map(
                                    (p: any) => (
                                      <li
                                        className={`${styles.socialPlatform}`}
                                      >
                                        <img
                                          src={`/imgs/${p.key.toLowerCase()}_square.svg`}
                                          alt={p.name}
                                        />
                                        <div>
                                          <span className={styles.plaform}>
                                            {p.name}
                                          </span>
                                          <span className={styles.services}>
                                            {p.services
                                              .map((s: string) => s)
                                              .join(", ")}
                                          </span>
                                        </div>
                                      </li>
                                    )
                                  )}
                                </ul>
                              </div>
                            ),
                          },
                        ]}
                        className="inf_details_accordion inf_info details_collapse"
                      />
                    </>
                  )}
                </div>
              </Col>
              <Col md={18} lg={18} xl={18} className={styles.col}>
                <div className={styles.campaignHeader}>
                  <div className={styles.cont}>
                    <Radio.Group
                      onChange={(e) =>
                        setFilters({ ...filters, status: e.target.value })
                      }
                      value={filters.status}
                      className="campaignStatus"
                    >
                      <Radio.Button value="">{t("all")}</Radio.Button>
                      <Radio.Button value="Pending">
                        {t("pending")}
                      </Radio.Button>
                      <Radio.Button value="Accepted">
                        {t("accepted")}
                      </Radio.Button>
                      <Radio.Button value="Rejected">
                        {t("rejected")}
                      </Radio.Button>
                    </Radio.Group>
                    <div className={styles.verticalSeperator}></div>
                    <Select
                      onChange={(v: any) =>
                        setFilters({ ...filters, platform: v.key })
                      }
                      value={filters.platform}
                      popupClassName="plat-drop-options"
                      labelInValue
                      className="plat-drop-campaign"
                      defaultActiveFirstOption={false}
                      // className={`brands-select ${styles.marginTop}`}
                      virtual={false}
                      showSearch={false}
                      size="large"
                      placeholder={t("platform_placeholder")}
                      onInputKeyDown={(event) => {
                        if (event.keyCode === 8 || event.key === "Backspace") {
                          return event.stopPropagation();
                        }
                      }}
                    >
                      <Option value={""}>
                        <div className={styles.platOptions}>
                          <HiOutlineShare />
                          <p>{t("all_platforms")}</p>
                        </div>
                      </Option>
                      {lookups &&
                        lookups[i18n.language]?.socialPlatforms?.map(
                          (s: any) => (
                            <Option value={s.key}>
                              <div className={styles.platOptions}>
                                <img
                                  src={`/imgs/${s.key.toLowerCase()}_square.svg`}
                                  alt={s.name}
                                />
                                <p>{s.name}</p>
                              </div>
                            </Option>
                          )
                        )}
                    </Select>
                  </div>
                  <button
                    className="lavad-btn lavad-btn-lg lavad-btn-primary-outline btn-icon"
                    onClick={() => setShowExportModal(true)}
                  >
                    <TbCloudDownload width={18} height={18} />{" "}
                    <span>{t("Export")}</span>
                  </button>
                </div>
                <div className={styles.CampaignDetails}>
                  {
                    <>
                      <Collapse
                        defaultActiveKey={"1"}
                        className="inf_details_accordion inf_info details_collapse ads_collapse"
                        items={[
                          {
                            label: (
                              <p className="alignItems">
                                <RiFileChartLine /> {t("ads_summary")}{" "}
                                <span>
                                  (
                                  {!filters.platform
                                    ? t("all_platforms")
                                    : lookups &&
                                    lookups[
                                      i18n.language
                                    ].socialPlatforms.find(
                                      (k: any) => k.key == filters.platform
                                    ).name}
                                  )
                                </span>
                              </p>
                            ),
                            key: 1,
                            children: (
                              <CampaignSummary
                                summary={summary}
                                loading={summaryLoading}
                              />
                            ),
                          },
                        ]}
                      />
                      <div className={styles.assignedInfluencers}>
                        <div className={styles.assignedInfluencers_header}>
                          <h5>
                            <HiOutlineUserGroup />
                            <span>
                              {t("assigned_influencers")}{" "}
                              <span className={styles.infNumbers}>
                                ({assignedInfluencers?.length || 0})
                              </span>
                            </span>
                          </h5>
                        </div>
                        <div className={styles.assignedInfluencersListWrapper}>
                          {assingedInfluencersLoading && (
                            <InfluencerServicesCardShimmer isMobile={false} />
                          )}
                          {assignedInfluencers?.length > 0 &&
                            !assingedInfluencersLoading &&
                            assignedInfluencers?.map((item: any) => (
                              <EnhancedInfluencerServicesCard
                                state="actions"
                                key={item.id}
                                influencer={item}
                                lisitingAssigned={true}
                                campaignGuid={id}
                                campaignId={details?.campaignSummary.id}
                                getData={getCampaignAssignedInfluencersList}
                                doUpdate={getCampaignDetails}
                              />
                            ))}

                          {assignedInfluencers?.length === 0 && (
                            <div className={styles.empty_assigned_influencers}>
                              <img
                                src={assingedInfluencerEmptyState}
                                alt="no data"
                              />
                              <div className={styles.empty_state_text}>
                                {filters.platform || filters.status ? (
                                  <h3>{t("no_assigned_with_filters")}</h3>
                                ) : (
                                  <h3>{t("no_assgined_influencers")}</h3>
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </>
                  }
                </div>
              </Col>
            </Row>
          </div>
          <div
            className={` ${styles.mobileView} ${assingedInfluencersLoading || loading || state.loading
              ? styles.noscroll
              : ""
              } ${collapsedView ? styles.collapsedMobile : ""}`}
            onScroll={handleScroll}
          >
            <>
              <div className={styles.Header}>
                {loading ? (
                  <div className={styles.paddingCont}>
                    <div className={styles.logo}>
                      <div className={`${styles.loadingImg} shimmer`}></div>
                      <div className={styles.header_info}>
                        <h3 className={`${styles.loadingname} shimmer`}></h3>

                        <h3 className={`${styles.loadingname} shimmer`}></h3>

                        <h3 className={`${styles.loadingname} shimmer`}></h3>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className={styles.paddingCont}>
                    <div className={styles.collapsed}>
                      <div className={styles.head}>
                        <img
                          ref={imgRef}
                          src={details?.campaignSummary.brand.logo}
                          alt="brand"
                          onError={handleImgError}
                        />
                      </div>
                      <h3 data-private>
                        {i18n.language == "en"
                          ? details?.campaignSummary.brand.name.en
                          : details?.campaignSummary.brand.name.ar}
                      </h3>
                      <div>
                        <h2 data-private>{details?.campaignSummary.name}</h2>
                        <button
                          className={`lavad-btn lavad-btn-lg lavad-btn-primary-outline btn-icon btnBlock ${styles.export}`}
                          onClick={() => setShowExportModal(true)}
                        >
                          <TbCloudDownload width={18} height={18} />{" "}
                          <span>{t("Export")}</span>
                        </button>
                      </div>
                      <Button
                        className={`${styles.status_label} ${labelClass}`}
                      >
                        {statusLoading ? (
                          <span className="icon-loader spin"></span>
                        ) : (
                          <Space>
                            {returnStatusIcon(status)}
                            {campaignStatus?.name}
                          </Space>
                        )}
                      </Button>
                    </div>
                    <Button className={`${styles.status_label} ${labelClass}`}>
                      {statusLoading ? (
                        <span className="icon-loader spin"></span>
                      ) : (
                        <Space>
                          {returnStatusIcon(status)}
                          {campaignStatus?.name}
                        </Space>
                      )}
                    </Button>
                    <div className={`${styles.lastActivity} body`}>
                      {t("latest_activity_on")}{" "}
                      {dayjs
                        .utc(
                          details?.auditSummary?.modificationInfo
                            ?.modificationDate
                        )
                        .local()
                        .format("DD/MM/YYYY  hh:mm A")}
                    </div>
                    {/* <button
                    className={`lavad-btn lavad-btn-lg lavad-btn-primary-outline btn-icon btnBlock ${styles.export}`}
                    onClick={() => setShowExportModal(true)}
                  >
                    <TbCloudDownload width={18} height={18} />{" "}
                    <span>{t("Export")}</span>
                  </button> */}
                  </div>
                )}
              </div>
              <div className={styles.CampaignDetails}>
                {
                  <>
                    <div className={styles.campaignHeader}>
                      {!loading ? (
                        <Collapse
                          items={[
                            {
                              key: "1",
                              label: (
                                <p className="alignItems">
                                  <RiListCheck2 /> {t("campaign_details")}
                                </p>
                              ),
                              children: (
                                <div className={styles.collapseDetails}>
                                  <div className={styles.collapseDetailsHead}>
                                    <div>
                                      <p>{t("create_campaign_objective")}</p>
                                      <span>
                                        {
                                          details?.campaignSummary
                                            .campaignObjectiveKey
                                        }
                                      </span>
                                    </div>

                                    <div className={styles.infType}>
                                      <p>{t("influencer_type")}</p>
                                      <span>
                                        {details?.influencerAndServices?.influencerTypeKeys.map(
                                          (e: any) => e
                                        )}
                                      </span>
                                    </div>
                                  </div>
                                  <ul className={styles.plats}>
                                    {details?.influencerAndServices?.socialPlatforms.map(
                                      (p: any) => (
                                        <li
                                          className={`${styles.socialPlatform}`}
                                        >
                                          <img
                                            src={`/imgs/${p.key.toLowerCase()}_square.svg`}
                                            alt={p.name}
                                          />
                                          <div>
                                            <span className={styles.plaform}>
                                              {p.name}
                                            </span>
                                            <span className={styles.services}>
                                              {p.services
                                                .map((s: string) => s)
                                                .join(", ")}
                                            </span>
                                          </div>
                                        </li>
                                      )
                                    )}
                                  </ul>
                                </div>
                              ),
                            },
                          ]}
                          className={`inf_details_accordion inf_info details_collapse ${styles.collapse}`}
                        />
                      ) : (
                        ""
                      )}
                      <div className={styles.cont}>
                        <p className={styles.filterTitle}>{t("filter_by")}</p>
                        <Radio.Group
                          onChange={(e) =>
                            setFilters({ ...filters, status: e.target.value })
                          }
                          value={filters.status}
                          className="campaignStatus"
                        >
                          <Radio.Button value="">{t("all")}</Radio.Button>
                          <Radio.Button value="Pending">
                            {t("pending")}
                          </Radio.Button>
                          <Radio.Button value="Accepted">
                            {t("accepted")}
                          </Radio.Button>
                          <Radio.Button value="Rejected">
                            {t("rejected")}
                          </Radio.Button>
                        </Radio.Group>
                        <div className={styles.verticalSeperator}></div>
                        <Select
                          onChange={(v: any) =>
                            setFilters({ ...filters, platform: v.key })
                          }
                          value={filters.platform}
                          popupClassName="plat-drop-options"
                          labelInValue
                          className="plat-drop-campaign"
                          defaultActiveFirstOption={false}
                          // className={`brands-select ${styles.marginTop}`}
                          virtual={false}
                          showSearch={false}
                          size="large"
                          placeholder={t("platform_placeholder")}
                          onInputKeyDown={(event) => {
                            if (
                              event.keyCode === 8 ||
                              event.key === "Backspace"
                            ) {
                              return event.stopPropagation();
                            }
                          }}
                        >
                          <Option value={""}>
                            <div className={styles.platOptions}>
                              <HiOutlineShare />
                              <p>{t("all_platforms")}</p>
                            </div>
                          </Option>
                          {lookups &&
                            lookups[i18n.language]?.socialPlatforms?.map(
                              (s: any) => (
                                <Option value={s.key}>
                                  <div className={styles.platOptions}>
                                    <img
                                      src={`/imgs/${s.key.toLowerCase()}_square.svg`}
                                      alt={s.name}
                                    />
                                    <p>{s.name}</p>
                                  </div>
                                </Option>
                              )
                            )}
                        </Select>
                      </div>
                      <Collapse
                        defaultActiveKey={"1"}
                        className={`inf_details_accordion inf_info details_collapse ads_collapse ${styles.adsCollapse}`}
                        items={[
                          {
                            label: (
                              <p className="alignItems">
                                <RiFileChartLine /> {t("ads_summary")}{" "}
                                <span>({t("all_platforms")})</span>
                              </p>
                            ),
                            key: 1,
                            children: (
                              <CampaignSummary
                                summary={summary}
                                loading={summaryLoading}
                                isMobile={
                                  window.matchMedia("(max-width:768px)").matches
                                }
                              />
                            ),
                          },
                        ]}
                      />

                      <div className={styles.assignedInfluencers_header}>
                        <h5>
                          <HiOutlineUserGroup />
                          <span>
                            {t("assigned_influencers")}{" "}
                            <span className={styles.infNumbers}>
                              ({assignedInfluencers?.length || 0})
                            </span>
                          </span>
                        </h5>
                      </div>
                    </div>
                  </>
                }
              </div>

              <div
                className={
                  styles.assignedInfluencersListWrapper +
                  " " +
                  "collapedAssignedList"
                }
              >
                {assingedInfluencersLoading && (
                  <InfluencerServicesCardShimmer isMobile={true} />
                )}
                {assignedInfluencers?.length > 0 &&
                  !assingedInfluencersLoading &&
                  assignedInfluencers?.map((item: any) => (
                    <EnhancedInfluencerServicesCard
                      state="actions"
                      key={item.id}
                      influencer={item}
                      lisitingAssigned={true}
                      campaignGuid={id}
                      campaignId={details?.campaignSummary?.id}
                      getData={getCampaignAssignedInfluencersList}
                      doUpdate={getCampaignDetails}
                    />
                  ))}

                {assignedInfluencers?.length === 0 && (
                  <div className={styles.empty_assigned_influencers}>
                    <img src={assingedInfluencerEmptyState} alt="no data" />
                    <div className={styles.empty_state_text}>
                      {filters.platform || filters.status ? (
                        <h3>{t("no_assigned_with_filters")}</h3>
                      ) : (
                        <h3>{t("no_assgined_influencers")}</h3>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </>
          </div>
          {showPPTExportLink && (
            <PPTExport
              campaginId={id}
              modalOpen={showPPTExportLink}
              setModalOpen={setShowPPTExportLink}
              onSuccess={() => setShowPPTExportLink(false)}
              campaignDetails={details}
            />
          )}
          {showExportModal && (
            <ExportModal
              campaginId={id}
              modalOpen={showExportModal}
              setModalOpen={setShowExportModal}
              campaignDetails={details}
              agencyDetails={state.agency}
            />
          )}
        </>
      )}
    </>
  );
};

export default CampaignDetails;
